import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import VideoRoomComponent from './components/VideoRoomComponent';
import registerServiceWorker from './registerServiceWorker';



ReactDOM.render(
  <VideoRoomComponent openviduServerUrl="https://easydoc.live" openviduSecret="123_QAZ_wsx_1987" />, document.getElementById('root')
);
registerServiceWorker();
